import React from 'react';

import { 
	Box,
	Container, 
	Typography 
} from '@mui/material';

import styles from "../../global.scss";
import "./styles.scss";

const SecurityPage = () => {
  return (
    <Container maxWidth="xl">
      <Box className="security">
        <Box sx={{ 
          margin: "15px 0px 100px 0px", 
          borderRadius: "30px",
          backgroundColor: styles["primary_light"], 
          height: "250px" }} 
          className="flexCenterCenterRow">
          <Typography
            sx={{ 
              textAlign: "center",
              typography: { xs: "h4", sm: "h4", md: "h3", lg: "h3" },
            }}>Security</Typography>
        </Box>

        <Typography variant='h6'>Secure technology</Typography> 
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          Lusid uses state-of-the-art cryptographic algorithms during data transmission (HTTPS with RSA 2048-bit key and SHA 256 certificate) and in our databases (AES 256 encryption keys). Lusid holds a SOC 2 Type 2 and ISO 27001, 27017 and 27018 certifications, and data is hosted in SOC 1, 2 and 3 certified data centers with 24×7 monitoring.
        </Typography>

        <Typography variant='h6'>PCI Compliant</Typography> 
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          The PCI DSS certification process is designed to protect your credit card sensitive data. Lusid does not store any credit card sensitive information on the servers but uses a card processor which is a certified Level 1 PCI Compliant Service Provider (the highest level), which requires an annual independent security audit of its processes and systems. Lusid and our 3rd party card processor test the system daily (manually and automatically) to ensure security.
        </Typography>

        <Typography variant='h6'>Protecting your money</Typography> 
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          Customer funds are held in a protected account by our bank providers until the funds are delivered to your vendor, so your funds are never at risk.
        </Typography>

        <Typography variant='h6'>Training and education</Typography> 
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          All Lusid employees undergo security training. The development team follows strict SDLC process which includes security validations and penetration tests.
        </Typography>

        <Typography variant='h6'>Reporting and disclosure</Typography> 
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          We investigate all reported vulnerabilities, so if you think you have discovered a problem with your account please email security@lusid.ai
        </Typography>

      </Box>
    </Container>
  )
}

export default SecurityPage;