import { Container, Typography } from '@mui/material'
import React from 'react'

const DevelopersPage = () => {
  return (
    <Container maxWidth="xl">
      <Typography variant='h3'>Developers Page</Typography>

    </Container>
  )
}

export default DevelopersPage