
const initialState = {
 
};

const contactRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
   
    default:
      return state;
  }
};

export default contactRequestsReducer;