import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
	Box, 
	Container, 
	Grid, 
	IconButton, 
	Link,
	Typography 
} from '@mui/material';

import Logo from "../../assets/icons/logo-white.svg";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';

import "./styles.scss";

const Footer = () => {
	const navigate = useNavigate();

	const handleNavigate = (route) => {
		handleGoTop();
		navigate(route);
	}

	const handleGoTop = () => {
		document.body.scrollTop = 0; // For Safari
  	document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}

  return (
    <Box className="footer">
      <Container maxWidth="xl">
				<Box>
					<Box sx={{ paddingTop: "40px", paddingBottom: "50px" }}>
						<img
							src={Logo}
							alt='lusid-footer-logo'
							style={{ width: "250px" }}
							/>
					</Box>

					<Grid 
						container
						columnSpacing={1}
						rowSpacing={6}
						className="flexFSSBRow" 
						sx={{ paddingTop: "40px", paddingBottom: "50px" }}>	
						<Grid item xs={12} sm={4} md={2.4} lg={2.4}>
							<Typography variant='h6' className='footer__heading'
								onClick={() => handleNavigate("/products")}>Products</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/products#web-app")}>Lusid Web App</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/products#mobile-app")}>Lusid Mobile App</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/products#extension")}>Lusid Extension</Typography>
						</Grid>
						<Grid item xs={12} sm={4} md={2.4} lg={2.4}>
							<Typography variant='h6' className='footer__heading'
								onClick={() => handleNavigate("/capabilities")}>Capabilities</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#instant-payment")}>Instant Payment</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#issue-virtual-card")}>Issue Virtual Card</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#control")}>Policy compliance</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#tap-to-pay")}>Tap to Pay</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#scan-to-pay")}>Scan to Pay</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#easy-bill-capture")}>Easy Bill Capture</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#pay-circle")}>Pay Circle</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#vendor")}>Payee/Vendor Management</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#automation")}>Spend Automation</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#expense-management")}>Expense Management</Typography>
							{/* <Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/capabilities#bio")}>Biometrics Payment</Typography> */}
						</Grid>
						<Grid item xs={12} sm={4} md={2.4} lg={2.4}>
							<Typography variant='h6' className='footer__heading'
								onClick={() => handleNavigate("/#solutions")}>Solutions</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/#solutions")}>Solutions Overview</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/#solutions")}>Never miss a bill</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/#solutions")}>Control & reconcile spend</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/#solutions")}>Policy Compliance</Typography>
						</Grid>
						<Grid item xs={12} sm={4} md={2.4} lg={2.4}>
							<Typography variant='h6' className='footer__heading'
								onClick={() => handleNavigate("/#developers")}>Developers</Typography>
							<Link
								href="https://developer.lusid.ai"
								target="_blank" rel="noopener noreferrer"
								sx={{ textDecoration: "none" }}
								>
								<Typography variant='body2' className='footer__item'>Developer Portal</Typography>
							</Link>
						</Grid>
						<Grid item xs={12} sm={4} md={2.4} lg={2.4}>
							<Typography variant='h6' className='footer__heading'>Company</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/about-us")}>About us</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/contact-us")}>Contact us</Typography>

							<Typography variant='h6' className='footer__heading' sx={{ marginTop: "40px" }}>Legal</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/terms-and-conditions")}>Terms and Conditions</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/security")}>Security</Typography>
							<Typography variant='body2' className='footer__item'
								onClick={() => handleNavigate("/privacy-policy")}>Privacy</Typography>
						</Grid>
					</Grid>

					<Box className="flexCenterSBRow">
						<IconButton onClick={handleGoTop}>
							<ArrowUpwardIcon fontSize='large' sx={{ color: "white"}} />
						</IconButton>

						<Box sx={{ paddingTop: "40px", paddingBottom: "50px" }}>
							<IconButton>
								<LinkedInIcon fontSize='large' sx={{ color: "white"}}/>
							</IconButton>
							<IconButton>
								<XIcon fontSize='large' sx={{ color: "white"}}/>
							</IconButton>
						</Box>
					</Box>
				</Box>
			</Container>
    </Box>
  )
}

export default Footer;