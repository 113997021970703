import React from 'react';

import { 
	Box,
	Container, 
	Typography 
} from '@mui/material';

import styles from "../../global.scss";
import "./styles.scss";

const TermsAndConditionsPage = () => {
  return (
    <Container maxWidth="xl">
      <Box className="terms">
        <Box sx={{ margin: "15px 0px 100px 0px", borderRadius: "30px", backgroundColor: styles["primary_light"], height: "250px" }} 
          className="flexCenterCenterRow">
          <Typography
            sx={{ 
              textAlign: "center",
              typography: { xs: "h5", sm: "h4", md: "h3", lg: "h3" },
            }}>Terms and Conditions</Typography>
        </Box>
        <Typography variant='h5'>1. Terms</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          By accessing this Website, accessible from https://lusid.ai, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.
        </Typography>

        <Typography variant='h5'>2. Use License</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          Permission is granted to temporarily download one copy of the materials on Lusid's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
          <ul style={{ marginLeft: "40px" }}>
            <li>modify or copy the materials;</li>
            <li>use the materials for any commercial purpose or for any public display;</li>
            <li>attempt to reverse engineer any software contained on Lusid's Website;</li>
            <li>remove any copyright or other proprietary notations from the materials; or</li>
            <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
          </ul>
          This will let Lusid to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.
        </Typography>

        <Typography variant='h5'>3. Disclaimer</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          All the materials on Lusid's Website are provided "as is". Lusid makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, Lusid does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.
        </Typography>

        <Typography variant='h5'>4. Limitations</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          Lusid or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on Lusid's Website, even if Lusid or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.
        </Typography>

        <Typography variant='h5'>5. Revisions and Errata</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          The materials appearing on Lusid's Website may include technical, typographical, or photographic errors. Lusid will not promise that any of the materials in this Website are accurate, complete, or current. Lusid may change the materials contained on its Website at any time without notice. Lusid does not make any commitment to update the materials.
        </Typography>

        <Typography variant='h5'>6. Links</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          Lusid has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by Lusid of the site. The use of any linked website is at the user's own risk.
        </Typography>

        <Typography variant='h5'>7. Site Terms of Use Modifications</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          Lusid may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.
        </Typography>

        <Typography variant='h5'>8. Your Privacy</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          Please read our Privacy Policy.
        </Typography>

        <Typography variant='h5'>9. Governing Law</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          Any claim related to Lusid's Website shall be governed by the laws of us without regards to its conflict of law provisions.
        </Typography>
      </Box>
		</Container>
  )
}

export default TermsAndConditionsPage;